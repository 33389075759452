.App {
  text-align: center;
}

.ant-card-body{
  padding: 0 !important;
}

.lineheader{
  height: 5px !important;
  width: 100%;
  z-index: 1;
  top: 0;
  position: absolute;
  background-color: #241C5C;
}

.headerStyle{
  text-align: "center";
  color: "#fff";
  height: 64px;
  padding-inline: 50;
  line-height: "64px";
  background-color: "#7dbcea";
}

.contentStyle{
  text-align: "center";
  min-height: 120;
  line-height: "120px";
  color: "#fff";
  background-color: "#108ee9";
}

.footerStyle {
  text-align: "center";
  color: "#fff";
  background-color: "#7dbcea";
}

.logo-institucion{
  width: 100%;
  max-width: 213px;
  height: auto;
  top: 7px;
  left: 0px;
  position: absolute;
  z-index: 1;
}

.fontFooter{
  font-style: italic;
  font-size: 14px !important;
  min-height: 100px !important;
}

.inputForm{
  /* width: 570px; */
}

.selectForm{
  /* width: 470px !important; */
}

.checkForm{
  /* width: 600px !important; */
}

.divTerms{
  text-align:'center' !important;
  /* width:600px !important; */
}

.directions li::marker {
  font-size: 90%;
  font-weight: bold;
  color: red;
}

ol.directions li::marker {
  content:"[" counter(list-item)"] ";
  color: red;
}

.pdfviewer{
  width: 100%;
  height: 600px;
}

#iframe{
  height: 600px;
}

@media (max-width: 720px) {
  .logo-institucion{
    width: 50%;
    max-width: 10px;
    height: auto;
    top: 20px;
    left: 270px;
    position: absolute;
    z-index: 1;
  }
  .logo{
    width: 100px;
  }
  .inputForm{
    /* width: 270px; */
  }
  .selectForm{
    padding-left:0 !important;
    /* width:270px !important; */
  }
  .checkForm{
    /* width: 255px !important; */
    font-size: 12px !important;
  }
  .divTerms{
    text-align:'center' !important;
    /* width:100px !important; */
  }
  
  .footerStyle {
    text-align: "center";
    color: "#fff";
    background-color: "#7dbcea";
    font-size: 10px;
  }
  .fontFooter{
    font-size: 10px !important;
    font-style: italic;
    min-height: 100px !important;
  }

  .pdfviewer{
    width: 100%;
    height: 400px;
  }
  #iframe{
    height: 400px;
  }
}
